<template>
  <div class="caigou">
      <gxjyheader title="采购信息" desc="品质铸就品牌  采购提升价值"></gxjyheader>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item :to="{ path: '/gxjy' }">供需信息</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/caigou' }">需方市场-采购</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-form ref="form" :model="form" style="background: #fff;margin: 20px 0;padding:10px 0" label-width="120px">
          <el-row>
            <el-col :span="8">
              <el-form-item label="产品名称" >
                <el-input placeholder="请输入"   style="width: 218px" v-model="form.productName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="采购类型">
                <el-select v-model="form.typeId" placeholder="请选择">
                  <el-option
                      v-for="item in getTypeList"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="行业">
                <el-select v-model="form.industryId" placeholder="请选择">
                  <el-option
                      v-for="item in getIndustryList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发布时间">
                <el-date-picker
                    class="riqi"
                    v-model="form.date"
                    type="datetimerange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                    :clearable="false"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-button type="primary" size="small" @click="loadlist(true)">筛选</el-button>
            <el-button @click="reset" size="small" >重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="lists">
        <div class="list" v-for="(item,index) in items">
          <div class="biao">需</div>
          <div class="h1">
            <div class="title ellipsis2">{{item.title}}</div>
            <div  class="type">
              采购类型： {{item.typeName}}
            </div>
            <div  class="type text-overflow">
              产品名称： {{item.productName}}
            </div>
            <div class="time">
              有效时间：{{item.endTime}}
            </div>
            <div class="need">
              需求企业：{{item.enterpriseName}}
            </div>
            <div class="number">
              <div class="card">
                <div class="cardred">{{item.minNumber}}{{item.unit}}</div>
                <div>最小采购量</div>
              </div>
            </div>
            <div>
              <i class="el-icon-location"></i>
              <span class="address colorC8">{{item.cityName}}</span>
              <span class="see"  @click="goxq(item)">查看详情</span>
            </div>
          </div>
        </div>
      </div>
      <div class="fenye">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="page.current"
            :page-sizes="[12, 60, 120, 200]"
            :page-size="page.size"
            :total="page.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import gxjyheader from './gxjyheader'
import {purchasegetIndustryList, purchasegetTypeList, purchaselist} from "../../request/moudle/demand";
import {mapGetters} from "vuex";
export default {
  name: "zhaobiao",
  beforeRouteEnter(to, from, next){
    if(from.name=='caigouxq'){
      next((vm) => {
        vm.form=vm.caigouform
      })
    }else{
      next()
    }
  },
  computed: {
    ...mapGetters(["caigouform"]),
  },
  data(){
    return {
      form:{},
      page:{
        current:1,
        size:12,
        total:0
      },
      getIndustryList:[],
      getTypeList:[],
      items:[],
    }
  },
  mounted() {
    this.loadconfig()
    this.loadlist()
  },
  methods:{
    loadconfig(){
      this.$api.demand.purchasegetIndustryList().then((res)=>{
        this.getIndustryList=res.data
      })
      this.$api.demand.purchasegetTypeList().then((res)=>{
        this.getTypeList=res.data
      })
    },
    goxq(item){
      this.$router.push({
        path:'/caigouxq',
        query:{
          id:item.id,
        }
      })
    },
    loadlist(bol){
      if(bol){
        this.page.current=1
      }
      if(this.form.date){
        this.form.startDate=this.form.date[0]
        this.form.endDate=this.form.date[1]
      }
      const obj={
        ...this.form
      }
      this.$store.commit("setcaigouform",this.form);
      obj.current=this.page.current
      obj.size=this.page.size
      this.$api.demand.purchaselist(obj).then((res)=>{
        this.items=res.data.records
        this.page.total=res.data.total
      })
    },
    handleSizeChange(val){
      this.page.size=val
      this.loadlist()
    },
    handleCurrentChange(val) {
      this.page.current=val
      this.loadlist()
    },
    reset(){
      this.form={}
    }
  },
  components:{
    gxjyheader
  },
}
</script>

<style lang="scss" scoped>
.caigou{
  background: #F5F5F5;
}
.colorC8{
  color: #C8C8C8;
}
  .content{
    width: 1200px;
    margin: 15px auto;
    .line{
      position: relative;
    }
    .sxx{
      background:#FFFFFF;
      padding:30px  40px;
      margin-top: 25px;
    }
    .fenye{
      text-align: center;
      padding: 30px 0;
      background: #fff;
    }
    .lists{
      overflow: hidden;
      background: #ffffff;
      padding:35px  20px;
      margin-top: 25px;
    }
    .list{
      width: 350px;
      border: 1px solid #D1D1D1;
      border-radius: 8px;
      float: left;
      margin-left: 20px;
      margin-bottom: 20px;
      line-height: 25px;
      .biao{
        width: 24px;
        height: 24px;
        border-radius: 1px;
        background: #0078F2;
        color: #fff;
        text-align: center;
        line-height: 24px;
        margin: 5px;
      }
      .title{
        font-size: 16px;
        color: #000000;
        font-weight: bolder;
        min-height: 50px;
      }
      .number{
        height: 60px;
        background: #E6E6E6;
        margin: 40px 0;
        position: relative;
        .cardred{
          color: red;
          font-size: 16px;
          font-weight: bolder;
          margin-top: 5px;
        }
        .card{
          width: 100%;
          display: inline-block;
          text-align: center;
        }
      }
      .el-icon-location{
        font-size: 32px;
        color: #D83434;
        vertical-align: middle;
      }
      .address{
        vertical-align: middle;
      }
      .see{
        display: inline-block;
        width: 110px;
        height: 35px;
        cursor: pointer;
        background: linear-gradient(0deg,#E7BD88,#E9C79A);
        color: #fff;
        text-align: center;
        float: right;
        line-height: 35px;
        border-radius: 4px;
      }
      .h1{
        margin: 10px 25px;
      }
    }
  }
</style>